import React, { useEffect, useCallback } from "react";
import style from "./Winner.module.scss";
import clsx from "clsx";
import styleTitle from "../../UI/title/Title.module.scss";
import { SafePic } from "react-safe-pic";
import imgPngIpad from "../../../images/winner/ipad.png";
import imgWebpIpad from "../../../images/winner/ipad.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { ReactComponent as Icon } from "../../../images/winner/arr.svg";
import btnIconStyle from "../../UI/btn/ButtonIcon.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  stepTo,
  setTopWinningIpad,
  setTopWinningIphone,
} from "../../../store/appSlice";
import styleBtn from "../../UI/btn/Button.module.scss";
import { quizGetTopWinning } from "../../../store/axs/quiz";
import { checkArr } from "../../../service/checkArr";

const Winner = () => {
  const dispatch = useDispatch();
  const jwt = window.localStorage.getItem("access");

  const {
    user: { firstName },
    stateAction,
    isMobileOrTablet,
    topWinningIpad,
  } = useSelector((state) => state.app);

  const fetchTopWinning = useCallback(async () => {
    try {
      const response = await quizGetTopWinning();
      //ipad
      dispatch(
        setTopWinningIpad(
          response.data.results.filter((i) => i.prize.main === false)
        )
      );

      //iphone
      dispatch(
        setTopWinningIphone(
          response.data.results.filter((i) => i.prize.main === true)
        )
      );
    } catch (error) {}
  }, [dispatch]);

  useEffect(() => {
    // if (!jwt) {
    //   return;
    // }
    fetchTopWinning();
  }, [fetchTopWinning]);

  return (
    <section id="winner" className={clsx(style.winner)}>
      <div className={clsx(style.winner__inner, "container")}>
        <div className={clsx(style.winner__title, styleTitle.title)}>
          <h2
            className={clsx(
              styleTitle.title__inner,
              styleTitle["title__inner--winner"]
            )}
          >
            победители
          </h2>
        </div>

        <div className={clsx(style.winner__content)}>
          <div className={clsx(style.winner__left)}>
            <figure className={clsx(style.winner__ipad)}>
              <SafePic regularImage={imgPngIpad} webpImage={imgWebpIpad} />
            </figure>

            <h3 className={clsx(style.winner__prize)}>iPad 10.9</h3>

            <p className={clsx(style.winner__period)}>Еженедельный</p>
          </div>

          <div
            className={clsx(
              style.winner__right,
              style.persons,
              "persons",
              !checkArr(topWinningIpad) && style["persons--empty"]
            )}
          >
            {!checkArr(topWinningIpad) && (
              <div className={clsx(style.empty)}>
                <p className={clsx(style.empty__text)}>
                  Первый розыгрыш еженедельного приза состоится 9 ноября
                  2023&nbsp;г.
                  <br />
                  Успей принять участие и загрузи свой чек.
                </p>

                {stateAction === 0 && (
                  <button
                    type="buton"
                    className={clsx(styleBtn.button, style.empty__btn)}
                    onClick={() =>
                      firstName
                        ? dispatch(stepTo({ type: "check", step: 1 }))
                        : dispatch(stepTo({ type: "auth", step: 1 }))
                    }
                  >
                    загрузить чек
                  </button>
                )}
              </div>
            )}
            {checkArr(topWinningIpad) && (
              <Swiper
                enabled={isMobileOrTablet}
                modules={[Pagination, Navigation]}
                pagination={{
                  clickable: true,
                  bulletClass: "bullet__item",
                  bulletElement: "span",
                  bulletActiveClass: "bullet__item--active",
                  modifierClass: "persons--",
                }}
                navigation={{
                  nextEl: ".persons--next",
                  prevEl: ".persons--prev",
                }}
                spaceBetween={0}
                breakpoints={{
                  769: {
                    slidesPerView: 3,
                    pagination: {
                      enabled: false,
                    },
                  },
                  320: {
                    slidesPerView: 1,
                    pagination: {
                      enabled: true,
                    },
                  },
                }}
                slidesPerView={3}
              >
                {topWinningIpad.map((item, i) => (
                  <SwiperSlide key={i}>
                    <div className={clsx(style.persons__item)}>
                      <p className={clsx(style.persons__date)}>
                        {item.drawingDate}
                      </p>
                      <p className={clsx(style.persons__name)}>
                        {item.winner.name}
                      </p>
                      <p className={clsx(style.persons__phone)}>
                        {item.winner.phone}
                      </p>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}

            {checkArr(topWinningIpad) && (
              <button
                className={clsx(
                  btnIconStyle.button,
                  btnIconStyle["button--xs"],
                  btnIconStyle["button--primary"],
                  style.persons__prev,
                  "persons--prev"
                )}
              >
                <Icon />
              </button>
            )}

            {checkArr(topWinningIpad) && (
              <button
                className={clsx(
                  btnIconStyle.button,
                  btnIconStyle["button--xs"],
                  btnIconStyle["button--primary"],
                  style.persons__next,
                  "persons--next"
                )}
              >
                <Icon />
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
export default Winner;
